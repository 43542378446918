import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/features/blog/layouts/PostLayout/PostLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .material-theme {
    background-color: #263238;
    color: #EEFFFF;
  }
  .material-theme .mtki { font-style: italic; }
  .material-theme .mtk8 { color: #82AAFF; }
  .material-theme .mtk3 { color: #89DDFF; }
  .material-theme .mtk12 { color: #C3E88D; }
  .material-theme .mtk1 { color: #EEFFFF; }
  .material-theme .mtk10 { color: #C792EA; }
  .material-theme .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>


    <p>{`If you are using Cloudways and/or their caching plugin Breeze you might have issues if you're running a WooCommerce shop with WPML Multi Currency Switcher - at least I did, after migrating to Cloudways and switching away from W3 Total Cache.`}</p>
    <p>{`The issue will only appear to non-admins, or guests/visitors visiting your site without being logged in (where cache is then not enabled, as per how Breeze works).`}</p>
    <p>{`After a bit of researching I realized that this had something to do with WPML and how they work, and found below filter to be working. Add it to your themes functions.php file or anywhere you would add custom coding to your WordPress installation.`}</p>
    <pre {...{
      "className": "grvsc-container material-theme",
      "data-language": "php",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk8"
            }}>{`add_filter`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`('`}</span><span parentName="span" {...{
              "className": "mtk12"
            }}>{`wcml_is_cache_enabled_for_switching_currency`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`',`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk10"
            }}>{`function`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`($`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{`cache_enabled`}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`)`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`{`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`    `}</span><span parentName="span" {...{
              "className": "mtk3 mtki"
            }}>{`return`}</span><span parentName="span" {...{
              "className": "mtk1"
            }}>{` `}</span><span parentName="span" {...{
              "className": "mtk3"
            }}>{`true;`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk3"
            }}>{`});`}</span></span></span></code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      